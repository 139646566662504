import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes, redirect } from 'react-router-dom'
import './scss/style.scss'
import cookie from "js-cookie";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import allActions from './actions/allActions';
import { CSpinner } from '@coreui/react';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const token = cookie.get("token");
  const currentUser = useSelector(state => state.currentUser)
  const dispatch = useDispatch();
  const [requestedUrl, setRequestedUrl] = useState("");
  useEffect(() => {
    console.log("toek");
    console.log(token);
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/AdminAuth/user-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data))

        }).catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut())

        });
    }
    if (token) {
      vaildateUser();
    }
    else {
      localStorage.clear();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1))
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const Loader = () => {
    return (
      <div className={'d-flex justify-content-center align-items-center h-100'}>
        <CSpinner color="primary" />
      </div>
    )
  }
  return (
    <HashRouter>
    <Suspense fallback={loading}>
      <Routes>
        <Route
          path="*"
          name="Main"
          element={
            currentUser?.loggedIn === true ? <DefaultLayout /> : token ? <Loader /> : <Login />
          }
        />

         {/* shoul be removed */}
        {/* <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route path="*" name="Home" element={<DefaultLayout />} /> */}
        {/* // */}

        {/* <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
      </Routes>
    </Suspense>
  </HashRouter>
  )
}

export default App
